import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { DeleteS3Object } from "../../../../utils/Functions/S3Storage/DeleteS3Object";
import { GenericDeleteGQL } from "../../../../utils/Functions/Graphql/GenericDeleteGQL";
import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getEvidenceControls } from "../graphql/getEvidenceControls";
import { submitRiskComplianceSyncJob } from "./submitRiskComplianceSyncJob";
import { updateControlOverrideType } from "../enums/updateControlOverrideType";
import { ListQueryBy } from "@rivial-security/appsync-utils";

/**
 * Deletes an Evidence resource in DB.
 *
 * Also deletes:
 *
 * - Evidence Activity Resources and Artifacts
 * - Evidence Control Links
 * - Evidence Tag Links
 * - Evidence Point of Contact Links
 * - Evidence KPI links
 * - Evidence Risk Control Links
 * - Evidence Audit Control Links
 *
 * @param {object} evidence - the evidence object to delete
 * @param {string} evidence.id - the id of the evidence
 * @param {string} evidence.ownerGroup - the associated organization id of the evidence
 * @returns {Promise<void>}
 * @constructor
 */
const deleteEvidence = async (evidence) => {
  if (!evidence?.id || !evidence?.ownerGroup) {
    ErrorLogger("Invalid input into deleteEvidence!", JSON.stringify({ evidence }));
    return null;
  }

  //Retrieve the evidence links before it gets deleted
  const { complianceControls, riskControls } = await getEvidenceControls({
    evidenceID: evidence?.id,
  });

  // Delete all evidence activity resources
  const { getQuery: activityQuery } = generateGraphql("Evidence", ["activity"], {
    activity: `(limit: 1000) {
      items {
        id
        documents (limit: 500) {
          items {
            file {
              bucket
              region
              key
            }
          }
        }
      }
    }`,
  });

  const evidenceFiles = await ItemQuery(activityQuery, evidence.id);

  try {
    if (evidenceFiles?.ownerGroup && evidenceFiles?.activity?.items && Array.isArray(evidenceFiles.activity.items)) {
      for (const evidenceActivity of evidenceFiles.activity.items) {
        if (evidenceActivity?.documents?.items && Array.isArray(evidenceActivity.documents.items)) {
          for (const fileObj of evidenceActivity.documents.items) {
            const file = fileObj?.file;
            if (file && file.bucket && file.key)
              await DeleteS3Object({
                bucketName: file.bucket,
                objectKey: file.key,
                organizationID: evidenceFiles.ownerGroup,
              });
          }
        }
      }
    }
  } catch (e) {
    //Abort deletion process (not all files could be deleted)
    ErrorLogger(`Couldn't delete all evidence activity files while deleting an evidence - ${e}`);
    return null;
  }

  const evidenceActivities = await ListQueryBy({
    query: activityByEvidence,
    variables: { evidenceID: evidence.id },
  });

  if (evidenceActivities?.length > 0) {
    await GenericDeleteGQL({ objectToDelete: { evidenceActivities } }).then(() =>
      InfoLogger(`Deleted evidenceActivities for evidence: ${evidence?.id}`),
    );
  }

  const { getQuery: connectionsQuery } = generateGraphql(
    "Evidence",
    [
      "activity",
      "pointOfContacts",
      "observations",
      "controls",
      "tags",
      "riskControls",
      "auditControlLinks",
      "__typename",
    ],
    {
      activity: `(limit: 200) {
        items {
          id
          __typename
        }
        nextToken
      }`,
      controls: `(limit: 1000) {
      items {
          id
          __typename
        }
        nextToken
      }`,
      pointOfContacts: `(limit: 200) {
        items {
          id
          __typename
        }
        nextToken
      }`,
      keyPerformanceIndicators: `(limit: 200) {
        items {
          id
          __typename
        }
        nextToken
      }`,
      riskControls: `(limit: 200) {
        items {
          id
          __typename
        }
        nextToken
      }`,
      tags: `(limit: 200) {
        items {
          id
          __typename
        }
        nextToken
      }`,
      observations: `(limit: 200) {
        items {
          id
          __typename
          recommendations (limit: 200) {
            items {
              id
              __typename
              recommendation {
                id
                __typename
                actionItems (limit: 200) {
                  items {
                    id
                    __typename
                    action {
                      id
                      __typename
                    }
                    __typename
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
        nextToken
      }`,
      auditControlLinks: `(limit: 1000) {
        items {
          id
          __typename
        }
        nextToken
      }`,
    },
  );

  const deleteResult = await QueryGetItem({
    query: connectionsQuery,
    itemId: evidence.id,
  }).then(async (evidence) => {
    InfoLogger(`Deleting evidence - ${evidence?.id}`);
    await GenericDeleteGQL({ objectToDelete: evidence });
  });

  //Trigger the control check on the previously connected resources if any (important this is done after the evidence is already deleted)
  if (
    (Array.isArray(complianceControls) && complianceControls.length > 0) ||
    (Array.isArray(riskControls) && riskControls.length > 0)
  ) {
    submitRiskComplianceSyncJob({
      controlOverrides: complianceControls,
      riskControlOverrides: riskControls,
      overrideType: updateControlOverrideType.EVIDENCE_DELETED,
      organizationID: evidence.ownerGroup,
    });
  }

  return deleteResult;
};

export default deleteEvidence;

const activityByEvidence = /* GraphQL */ `
  query ActivityByEvidence(
    $evidenceID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEvidenceActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activityByEvidence(
      evidenceID: $evidenceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        controlSets(limit: 2000) {
          items {
            id
            __typename
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
