import * as Sentry from "@sentry/react";

import { API, graphqlOperation } from "@aws-amplify/api";
import { ErrorLogger, InfoLogger } from "@utils/EventLogger";
import { useEffect, useState } from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { useCheckPermissions } from "../permissions/useCheckPermissions/useCheckPermissions";

/**
 * @description A custom hook for querying DB with filter by the organization.
 * @param {string} query - is the graphql type object which contains a query to fetch from database
 * @param {string} organizationID - organization id of current selected organization
 * @param {number} limit - for specifying a query limit
 * @param {number} pageSize - for specifying paginated page size
 * @param {string} filterBy - use it if you want to filter your paginated list by a specific field
 * @param {object} subscriptionHook - pass a useSubscription hook with proper configurations, see useSubsctiption hook
 * @param {object} otherFiltersInitial - use it if you want to fetch data with a specific filters
 * @param {string} module - the name of the Module you want to access
 * @param {string} resource - resource name that you want to access
 * @param {boolean} disableRoleChecking - disable role checking
 * @param {string} typename - specify name of the objects for the query
 * @param {string[]} fields
 * @param {object} nestedFields
 * @param {boolean} [enableQuery = true] - if this is false, no query will be performed. useful for lazy loading the query
 * IMPORTANT: when `variables` are provided, parameters `organizationID` and `otherFiltersInitial` are ignored
 * @param {object} variables - graphql variables to pass through to the query
 * @returns {object} {{
 * {boolean} isLoading - returns if query still fetching data from database.
 * {string} setOtherFilters - use to set new filters for the query
 * nextToken: type string, get a string that shows a token if query needs to fetch more information from database
 * reset: type function, use it if you want to refetch data from database with current filters
 * list: type array, get a list of objects after query finished fetching data from database
 * setList: type function, use it if you want to reset state of list field
 * listPageArray: type 2d array, contains an array of arrays with paginated data
 * setOrganizationId: type function, use it if your selected organization changed and you want to perform new query
 * }}
 */

const useListQuery = ({
  query,
  organizationID,
  limit,
  pageSize,
  filterBy,
  subscriptionHook,
  otherFiltersInitial = undefined,
  module,
  resource,
  disableRoleChecking = false,
  typename,
  fields,
  nestedFields,
  enableQuery: enableQueryInit = true,
  variables,
}) => {
  const checkPermissionsHook = useCheckPermissions({
    module: module,
    resource: resource,
    disableRoleChecking,
  });

  const [enableQuery, setEnableQuery] = useState(enableQueryInit);
  const [nextToken, setNextToken] = useState(null);
  const [list, setList] = useState([]);
  const [listPageArray, setListPageArray] = useState([]);
  const [orgId, setOrgId] = useState(organizationID);
  const [isLoading, setIsLoading] = useState(true);
  const [otherFilters, setOtherFilters] = useState(undefined);

  const setOrganizationId = (id) => {
    setOrgId(id);
  };

  useEffect(() => {
    if (organizationID !== orgId) {
      setOrgId(organizationID);
    }
  }, [organizationID]);

  useEffect(() => {
    reset();
  }, [orgId]);

  useEffect(() => {
    if (checkPermissionsHook.resource.read === true) {
      if (subscriptionHook && subscriptionHook.onCreate) {
        const res = list && list.find((item) => item.id === subscriptionHook.onCreate.id);
        if (!res) {
          InfoLogger(
            `Subscription: Item ${
              subscriptionHook.onCreate && subscriptionHook.onCreate.id
            } was created, adding item to the list.`,
          );

          setList([...list, subscriptionHook.onCreate]);
        }
      }
    }
  }, [subscriptionHook && subscriptionHook.onCreate]);

  useEffect(() => {
    if (checkPermissionsHook.resource.read === true) {
      if (subscriptionHook && subscriptionHook.onUpdate) {
        const itemIndexInOriginalList = list && list.findIndex((item) => item.id === subscriptionHook.onUpdate.id);

        if (itemIndexInOriginalList > -1) {
          InfoLogger(
            `Subscription: Item ${
              subscriptionHook.onUpdate && subscriptionHook.onUpdate.id
            } was updated, updating item in the list.`,
          );

          list[itemIndexInOriginalList] = {
            ...list[itemIndexInOriginalList],
            ...subscriptionHook.onUpdate,
          };
          setList([...list]);
        }
      }
    }
  }, [subscriptionHook && subscriptionHook.onUpdate]);

  useEffect(() => {
    if (checkPermissionsHook.resource.read === true) {
      if (subscriptionHook && subscriptionHook.onDelete) {
        const itemFoundIndex = list && list.findIndex((item) => item.id === subscriptionHook.onDelete.id);

        if (itemFoundIndex > -1) {
          InfoLogger(
            `Subscription: Item ${
              subscriptionHook.onDelete && subscriptionHook.onDelete.id
            } was deleted, deleting item from the list.`,
          );

          list.splice(itemFoundIndex, 1);
          setList([...list]);
        }
      }
    }
  }, [subscriptionHook && subscriptionHook.onDelete]);

  useEffect(() => {
    fetchOperation(otherFilters || otherFiltersInitial);
  }, [nextToken, enableQuery]);

  const reset = (filters) => {
    if (checkPermissionsHook.resource.read === true) {
      setList([]);
      setNextToken(null);
      fetchOperation(otherFilters || filters || otherFiltersInitial);
    }
  };

  const resetFilters = (filters) => {
    setOtherFilters(filters);
    reset(filters);
  };

  const fetchOperation = (otherFilters = undefined) => {
    // Do not allow querying if the query is disabled
    if (enableQuery != true) {
      return;
    }

    if (checkPermissionsHook.resource.read === true) {
      setIsLoading(true);
      API.graphql(
        graphqlOperation(fields && typename ? generateGraphql(typename, fields, nestedFields).listQuery : query, {
          ...(variables ?? {
            filter: {
              ownerGroup: {
                eq: orgId,
              },
              ...otherFilters,
            },
          }),
          limit: limit ? limit : 250,
          nextToken: nextToken ? nextToken : undefined,
        }),
      )
        .then((data) => {
          const typeName = Object.keys(data.data)[0];

          if (data && data.data && data.data[typeName] && data.data[typeName].nextToken) {
            setNextToken(data && data.data && data.data[typeName] && data.data[typeName].nextToken);
          } else {
            setIsLoading(false);
          }

          const listArray = data && data.data && data.data[typeName] && data.data[typeName].items;

          if (listArray) {
            /**
             * listArray {Array} Array of fetched items from the database
             * list {Array} Array of all items
             *
             * 1. Concatenate listArray with the list array
             * 2. Filter returned array
             *     v - an item from array
             *     i - is a index in array of the "v" item
             *     a - is the array of all items
             * 3. For every "v" item we try to find an index in the main "a" array by comparing the predicate "t" id with id of the "v" which we got from predicate of the filter function.
             * 4. If we found one it will return an index and if this index matches with the index "i" then we have a duplicate and it will be filtered.
             */
            setList((list) => list.concat(listArray).filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i));
          }
        })
        .catch((e) => {
          const errors = e?.errors || {};
          ErrorLogger("Error while fetching data: ", JSON.stringify({ errors }));
          Sentry.captureException(e);
        })
        .finally(() => setIsLoading(false));
    } else {
      setList([]);
      ErrorLogger(`You don't have permissions to use ${module} -> ${resource}`);
    }
  };

  useEffect(() => {
    if (checkPermissionsHook.resource.read === true) {
      if (list && list.length > 0) {
        listPageArray.length = 0;
        getPageArray();
      }
    }
  }, [list]);

  const getPageArray = () => {
    if (checkPermissionsHook.resource.read === true) {
      const sortedArray = list.sort((a, b) => (a[filterBy] && b[filterBy] ? b[filterBy] - a[filterBy] : undefined));
      let tempPageArray = [];
      let counter = 0;

      if (sortedArray && sortedArray.length > 0) {
        let i;
        for (i = 0; i < sortedArray.length; i++) {
          counter++;

          if (counter <= pageSize) tempPageArray.push(list[i]);

          if (counter === pageSize) {
            listPageArray.push(tempPageArray);

            tempPageArray = [];
            counter = 0;
          } else if (i + 1 === sortedArray.length) {
            if ((sortedArray.length / pageSize) % 1 !== 0) {
              listPageArray.push(tempPageArray);
              tempPageArray = [];
            }
          }
        }
      }
    }
  };

  return {
    list,
    setList,
    nextToken,
    listPageArray,
    setOrganizationId,
    reset,
    setOtherFilters: (filters) => resetFilters(filters),
    isLoading,
    setIsLoading,
    enableQuery,
    setEnableQuery,
  };
};

export default useListQuery;
