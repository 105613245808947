import { ItemMutation, ListQueryBy } from "@rivial-security/appsync-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { GenericDeleteGQL } from "@utils/Functions/Graphql/GenericDeleteGQL";
import { InfoLogger } from "@utils/EventLogger";

/**
 * @description Archive a single Evidence Activity item from the database
 * NOTE: this function is archiving the evidence activity item, not deleting it
 * @param {object} item - evidence activity, grouped or ungrouped
 * @returns {Promise<null|*>}
 */
export const deleteEvidenceActivity = async (item) => {
  if (Array.isArray(item.activities)) {
    // in case this is a grouped activity item handle to delete recursively
    for (const activity of item.activities) {
      await deleteEvidenceActivity(activity);
    }
  } else {
    //Archive a single activity item
    const { updateMutation } = generateGraphql("EvidenceActivity");
    await ItemMutation({
      mutation: updateMutation,
      input: {
        id: item?.id,
        archived: true,
      },
    });

    const items = await ListQueryBy({
      query: controlSetEvidenceActivityLinkByEvidenceActivity,
      variables: {
        evidenceActivityID: item?.id,
      },
    });

    await GenericDeleteGQL({
      objectToDelete: {
        items,
      },
    }).then(() => InfoLogger(`ControlSetEvidenceActivityLinks were successfully deleted`));
  }
};

const controlSetEvidenceActivityLinkByEvidenceActivity = /* GraphQL */ `
  query ControlSetEvidenceActivityLinkByEvidenceActivity(
    $evidenceActivityID: ID
    $controlSetID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelControlSetEvidenceActivityLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    controlSetEvidenceActivityLinkByEvidenceActivity(
      evidenceActivityID: $evidenceActivityID
      controlSetID: $controlSetID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        __typename
      }
      nextToken
    }
  }
`;
