/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://7zy5b25hirf6zogxbg3ac4rndi.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:3926e5df-1ee3-4a3d-8b44-d99f12fa9eeb",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_JLg36LRhn",
    "aws_user_pools_web_client_id": "5ch92djjnnlr5frp38kq38ctj1",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 12,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "risc-prod-hostingbucket-testing",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d22moti88feyt7.cloudfront.net",
    "aws_user_files_s3_bucket": "evidence-storage-testing",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
