import { CUSTOM_CONTROL_FRAMEWORK_DASHBOARD_NAME } from "@compliance/api";
import client from "../../../apolloClient";

export interface GetComplianceFrameworkOverviewPageTitleParams {
  resourceID?: string;
}

export const getComplianceFrameworkDashboardTitle = async ({
  resourceID,
}: GetComplianceFrameworkOverviewPageTitleParams): Promise<string | null> => {
  if (!resourceID) {
    return null;
  }

  const dashboardResult = await client.query({
    query: CUSTOM_CONTROL_FRAMEWORK_DASHBOARD_NAME,
    variables: { dashboardId: resourceID },
  });

  return dashboardResult?.data?.customControlFrameworkDashboard?.name ?? null;
};
