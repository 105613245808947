import * as RadixTooltip from "@radix-ui/react-tooltip";

import Text, { TextVariant } from "@components/Atoms/Text/Text";

import { ReactElement } from "react";
import cx from "classnames";
import styles from "./Tooltip.module.scss";

export enum TooltipPlacement {
  TOP = "top",
  RIGHT = "right",
  BOTTOM = "bottom",
  LEFT = "left",
}

export enum TooltipAlign {
  START = "start",
  CENTER = "center",
  END = "end",
}
export interface TooltipProps {
  /** The target element that triggers the tooltip. */
  children: React.ReactNode;
  /** The content to be displayed in the tooltip. */
  content: React.ReactNode;
  /** The position of the tooltip relative to the target element. */
  placement?: TooltipPlacement;
  /** The alignment of the tooltip content within the tooltip. */
  align?: TooltipAlign;
  /** Whether the tooltip should be disabled and not displayed. */
  disabled?: boolean;
  /**
   * Whether the tooltip content should be rendered in a portal.
   * Set to `true` to ensure the tooltip escapes its parent overflow container.
   * Set to `false` to allow its events to bubble up to its containing elements.
   */
  inPortal?: boolean;
  /** Props to pass to each sub component of `Tooltip`. */
  slotProps?: {
    provider?: RadixTooltip.TooltipProviderProps;
    root?: RadixTooltip.TooltipProps;
    trigger?: RadixTooltip.TooltipTriggerProps;
    portal?: RadixTooltip.TooltipPortalProps;
    content?: RadixTooltip.TooltipContentProps;
    arrow?: RadixTooltip.TooltipArrowProps;
  };
}

/**
 * A reusable `Tooltip` component that displays additional information when a user interacts with a target element.
 */
export const Tooltip = ({
  children,
  content,
  disabled,
  placement = TooltipPlacement.TOP,
  align = TooltipAlign.CENTER,
  inPortal = true,
  slotProps,
}: TooltipProps): ReactElement => {
  const tooltipContent = (
    <RadixTooltip.Content
      side={placement}
      role="tooltip"
      align={align}
      {...slotProps?.content}
      className={cx(styles.tooltip__content, slotProps?.content?.className)}
    >
      <Text variant={TextVariant.CAPTION} htmlElement="span">
        {content}
      </Text>
      <RadixTooltip.Arrow className={styles.tooltip__arrow} {...slotProps?.arrow} />
    </RadixTooltip.Content>
  );

  return (
    <RadixTooltip.Provider {...slotProps?.provider}>
      <RadixTooltip.Root delayDuration={100} {...slotProps?.root}>
        <RadixTooltip.Trigger asChild {...slotProps?.trigger}>
          {children}
        </RadixTooltip.Trigger>
        {!disabled &&
          (inPortal ? (
            <RadixTooltip.Portal {...slotProps?.portal}>{tooltipContent}</RadixTooltip.Portal>
          ) : (
            tooltipContent
          ))}
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

export default Tooltip;
